import {css} from "lit-element/lit-element.js";
export default css`#container {
  display: block;
  width: 100%;
  font-size: 0.8em;
}
#container #content {
  display: flex;
  flex-direction: row;
  margin: 32px;
  color: #9e9c98;
}
#container #content .spacer {
  flex: 1;
}
#container #content .link {
  margin-left: 16px;
}
#container #content a {
  color: #9e9c98;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9jbGllbnQvc3JjL2NvbW1vbiIsInNvdXJjZXMiOlsiZm9vdGVyLnNjc3MiLCJjb21tb24uc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNDO0VBQ0E7RUFDQTs7QUFFQTtFQUNDO0VBQ0E7RUFDQSxRQ0hjO0VESWQsT0NQSzs7QURTTDtFQUNDOztBQUdEO0VBQ0MsYUNWWTs7QURhYjtFQUNDLE9DbEJJIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCBcIi4vY29tbW9uLnNjc3NcIjtcblxuI2NvbnRhaW5lciB7XG5cdGRpc3BsYXk6IGJsb2NrO1xuXHR3aWR0aDogMTAwJTtcblx0Zm9udC1zaXplOiAwLjhlbTtcblxuXHQjY29udGVudCB7XG5cdFx0ZGlzcGxheTogZmxleDtcblx0XHRmbGV4LWRpcmVjdGlvbjogcm93O1xuXHRcdG1hcmdpbjogJG1lZGl1bS1tYXJnaW47XG5cdFx0Y29sb3I6ICRncmF5O1xuXG5cdFx0LnNwYWNlciB7XG5cdFx0XHRmbGV4OiAxO1xuXHRcdH1cblxuXHRcdC5saW5rIHtcblx0XHRcdG1hcmdpbi1sZWZ0OiAkc21hbGwtbWFyZ2luO1xuXHRcdH1cblxuXHRcdGEge1xuXHRcdFx0Y29sb3I6ICRncmF5O1xuXHRcdH1cblx0fVxufVxuIiwiJGZnLWNvbG9yOiAjMmEyYTJhO1xuJGJnLWNvbG9yOiAjZjVmNGYzO1xuJGRhcmstZ3JheTogIzJhMmEyYTtcbiRsaWdodC1ncmF5OiAjYmZiZWJmO1xuJGdyYXk6ICM5ZTljOTg7XG5cbiRsYXJnZS1tYXJnaW46IDQ4cHg7XG4kbWVkaXVtLW1hcmdpbjogMzJweDtcbiRzbWFsbC1tYXJnaW46IDE2cHg7XG4keC1zbWFsbC1tYXJnaW46IDhweDtcbiJdfQ== */`;
