import "./banner.js";
import "./canvas.js";
import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";
import styles from "./main.scss";

@customElement("landing-main")
class LandingMain extends LitElement {
	static readonly styles = styles;

	render() {
		return html`
			<div id="container">
				<landing-banner></landing-banner>
				<div class="spacer"></div>
				<div id="center">
					<div class="spacer"></div>
					<div id="copy">
						<slot></slot>
					</div>
					<landing-canvas-image></landing-canvas-image>
				</div>
				<div class="spacer"></div>
			</div>
		`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		"landing-main": LandingMain;
	}
}
