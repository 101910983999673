import styles from "./canvas.scss";
import { LitElement, PropertyValueMap, html } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { customElement, property, query, state } from "lit/decorators.js";
import { repeat } from "lit/directives/repeat.js";
import { styleMap } from "lit/directives/style-map.js";
import { when } from "lit/directives/when.js";

interface FakeSegmentData {
	name: string;
	start: number;
	duration: number;
	position: number;
	label: string;
	waveform?: number[];
}

export interface FakeSessionData {
	name: string;
	segments: FakeSegmentData[];
}

@customElement("landing-canvas-image")
class LandingCanvasImage extends LitElement {
	@property({ type: Object }) data?: FakeSessionData = fakeSessionData;
	@property({ type: Number }) segmentHeight = 100;
	@property({ type: Number }) pixelsPerMinute = 300;
	@property({ type: Number }) animationTime = 0.8;
	@property({ type: Number }) animationDelay = 0.8;

	@state() private isVisible = false;
	@query("#container") private container?: HTMLDivElement;

	private onScreen?: IntersectionObserver;

	protected firstUpdated(
		_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
	): void {
		this.onScreen = new IntersectionObserver(
			(entries) => {
				this.isVisible = this.isVisible || entries[0].isIntersecting;
			},
			{
				threshold: 0.25,
			}
		);
		if (!this.container) {
			return;
		}
		this.onScreen.observe(this.container);
	}

	disconnectedCallback(): void {
		super.disconnectedCallback();
		this.onScreen?.disconnect();
	}

	protected willUpdate(changedProperties: PropertyValueMap<any>): void {
		if (changedProperties.has("data")) {
			// fill in the waveform data if it doesn't exist
			this.data?.segments.map((s) => {
				if (!s.waveform) {
					s.waveform = new Array(Math.min(s.duration))
						.fill(0)
						.map(() => Math.random() * this.segmentHeight);
				}
			});
		}
	}

	private get totalDuration() {
		return Math.max(
			...(this.data?.segments.map((s) => s.start + s.duration) || [])
		);
	}

	private get totalPosition() {
		return (
			Math.max(...(this.data?.segments.map((s) => s.position) || [])) + 1
		);
	}

	private getAnimationTime(seconds: number): string {
		return `${
			this.animationDelay +
			(seconds / this.totalDuration) * this.animationTime
		}s`;
	}

	/**
	 * Format time as a string
	 */
	private formatTime(time: number, precision = 0): string {
		let seconds, padding;
		const sign = Math.sign(time);
		time = Math.abs(time);
		const minutes = Math.floor(time / 60).toFixed(0);
		if (precision > 0) {
			seconds = (time % 60).toFixed(precision);
			padding = precision + 1;
		} else {
			seconds = `${Math.floor(time % 60)}`;
			padding = 0;
		}
		return `${sign < 0 ? "-1" : ""}${minutes.padStart(
			2,
			"0"
		)}:${seconds.padStart(2 + padding, "0")}`;
	}

	private renderSegment(segment: FakeSegmentData) {
		if (!(this.data && this.data.segments.length)) {
			return;
		}

		return html`
			<div
				class="segment"
				style=${styleMap({
					height: `${this.segmentHeight}px`,
					top: `${segment.position * this.segmentHeight}px`,
					left: `${this.pixelsPerMinute * (segment.start / 60)}px`,
					width: `${
						this.pixelsPerMinute * (segment.duration / 60)
					}px`,
					"--segment-color": `var(--color-${segment.label})`,
				})}
			>
				<div id="waveform">
					${repeat(
						segment.waveform || [],
						(height, i) => html`
							<div class="bar">
								<div
									id="fill"
									style=${styleMap({
										height: `${height * 0.9}px`,
										animationDelay: this.getAnimationTime(
											segment.start + i
										),
										animationDuration: `${this.animationTime}s`,
									})}
								></div>
							</div>
						`
					)}
				</div>
				<div id="text">
					<studio-text size="small-text">${segment.name}</studio-text>
				</div>
			</div>
		`;
	}

	private renderGrid() {
		const numGrid = Math.ceil(this.totalDuration / 30);
		const lines = new Array(numGrid).fill(0).map((_, i) => i * 30);
		return html`
			${repeat(
				lines,
				(time) => html`
					<div
						class="line"
						style=${styleMap({
							animationDelay: this.getAnimationTime(time),
							animationDuration: `${this.animationTime / 2}s`,
						})}
					>
						<div id="line"></div>
						<div id="time">${this.formatTime(time)}</div>
					</div>
				`
			)}
		`;
	}

	static readonly styles = styles;

	render() {
		return html`
			<div
				id="container"
				class=${classMap({
					visible: this.isVisible,
				})}
			>
				<div
					id="content"
					style=${styleMap({
						height: `${
							this.totalPosition * this.segmentHeight + 20
						}px`,
						width: `${
							this.totalDuration * (this.pixelsPerMinute / 60)
						}px`,
					})}
				>
					<div id="grid">${this.renderGrid()}</div>
					<div id="segments">
						${repeat(this.data?.segments || [], (segment) =>
							this.renderSegment(segment)
						)}
					</div>
				</div>
			</div>
		`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		"landing-canvas-image": LandingCanvasImage;
	}
}

const fakeSessionData: FakeSessionData = {
	name: "My session",
	segments: [
		{
			start: 0,
			duration: 8,
			position: 0,
			name: "Synth",
			label: "synth",
		},
		{
			start: 12,
			duration: 8,
			position: 0,
			name: "Synth",
			label: "synth",
		},
		{
			start: 24,
			duration: 8,
			position: 0,
			name: "Synth",
			label: "synth",
		},
		{
			start: 36,
			duration: 8,
			position: 0,
			name: "Synth",
			label: "synth",
		},
		{
			start: 48,
			duration: 8,
			position: 0,
			name: "Synth",
			label: "synth",
		},
		{
			start: 60,
			duration: 8,
			position: 0,
			name: "Synth",
			label: "synth",
		},
		{
			start: 80,
			duration: 8,
			position: 0,
			name: "Synth",
			label: "synth",
		},
		{
			start: 4,
			duration: 20,
			position: 1,
			name: "Drums",
			label: "drums",
		},
		{
			start: 30,
			duration: 20,
			position: 1,
			name: "Drums",
			label: "drums",
		},
		{
			start: 64,
			duration: 20,
			position: 1,
			name: "Drums",
			label: "drums",
		},
		{
			start: 2,
			duration: 140,
			position: 2,
			name: "Guitar",
			label: "guitar",
		},
		{
			start: 0,
			duration: 5,
			position: 3,
			name: "Vocals",
			label: "vocals",
		},
		{
			start: 15,
			duration: 15,
			position: 3,
			name: "Vocals",
			label: "vocals",
		},
		{
			start: 45,
			duration: 10,
			position: 3,
			name: "Vocals",
			label: "vocals",
		},
		{
			start: 60,
			duration: 4,
			position: 3,
			name: "Vocals",
			label: "vocals",
		},
	],
};
