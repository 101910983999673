import {css} from "lit-element/lit-element.js";
export default css`:host {
  display: block;
  height: 100%;
}

#container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
#container #main {
  flex: 1;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9jbGllbnQvc3JjIiwic291cmNlcyI6WyJtYWluLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDSTtFQUNBOzs7QUFHSjtFQUNJO0VBQ0E7RUFDQTs7QUFFQTtFQUNJIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCBcIi4vY29tbW9uL2NvbW1vbi5zY3NzXCI7XG5cbjpob3N0IHtcbiAgICBkaXNwbGF5OiBibG9jaztcbiAgICBoZWlnaHQ6IDEwMCU7XG59XG5cbiNjb250YWluZXIge1xuICAgIGhlaWdodDogMTAwJTtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG5cbiAgICAjbWFpbiB7XG4gICAgICAgIGZsZXg6IDE7XG4gICAgfVxufVxuIl19 */`;
