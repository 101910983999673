import countdown, { DAYS } from "countdown";
import { addDays, isAfter } from "date-fns";

const CLOSE_DATE = new Date("07/23/2024");
/**
 * The number of days between close and shut down
 */
const SHUT_DOWN_DAYS = 31;

/**
 * The Date when all documents will be deleted.
 */
const SHUT_DOWN_DATE = addDays(CLOSE_DATE, SHUT_DOWN_DAYS);

/**
 * Get the number of days until the shut down date
 * if the date has passed, returns 0.
 */
export function daysLeft(): number {
	if (afterShutDownDate()) {
		return 0;
	}
	const days = (
		countdown(new Date(), SHUT_DOWN_DATE, DAYS) as countdown.Timespan
	).days;
	return days || 0;
}

/**
 * If the shut down date has already passed.
 */
export function afterShutDownDate() {
	return isAfter(new Date(), SHUT_DOWN_DATE);
}

/**
 * The date after which all data will be deleted.
 */
export function getShutdownDate() {
	return SHUT_DOWN_DATE.toLocaleDateString(undefined, {
		month: "short",
		day: "numeric",
		year: "numeric",
	});
}
