import { EmailAuthProvider, GoogleAuthProvider, getAuth } from "firebase/auth";
import { auth } from "firebaseui";
import { LitElement, html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { FRESH_DESK_SUPPORT } from "../common/links.js";
import "../firebase/init.js";
import styles from "./auth.scss";
import firebaseSigninStyles from "./firebase-ui.scss";

@customElement("sign-in-auth")
class SignInAuth extends LitElement {
	@state() private loading = true;
	@query("#auth-container") private authContainer?: HTMLDivElement;

	static readonly styles = [styles, firebaseSigninStyles];

	/**
	 * Firebase Auth's config object
	 */
	private config: auth.Config = {
		callbacks: {
			signInSuccessWithAuthResult: (authResult) => {
				// Throw if no user present
				if (!authResult.user) {
					throw new Error("auth returned null user");
				}

				// The return type in this function determines whether we continue
				// the redirect automatically or handle ourselves.
				return true;
			},
			signInFailure: function (error) {
				console.error(error);
			},
		},
		signInSuccessUrl: "/download",
		signInOptions: [
			{
				provider: EmailAuthProvider.PROVIDER_ID,
				requireDisplayName: true,
				//disableSignUp
				disableSignUp: {
					status: true,
				},
			},
			{
				provider: GoogleAuthProvider.PROVIDER_ID,
				disableSignUp: {
					status: true,
				},
			},
		],
		// needed for this to work on Safari
		// https://github.com/firebase/firebaseui-web/issues/977
		signInFlow: "popup",
	};

	protected firstUpdated() {
		if (!this.authContainer) {
			return;
		}

		this.changeFirebaseAuthTextLabels();

		let ui = auth.AuthUI.getInstance();
		if (!ui) {
			ui = new auth.AuthUI(getAuth());
		}

		const pendingRedirect = ui.isPendingRedirect();
		if (!pendingRedirect) {
			this.loading = false;
		}
		ui.start(this.authContainer, this.config);
	}

	/**
	 * Firebase auth UI does not apear to have a way to change the labels on the
	 * inputs they are asking for. This function will change the labels for the
	 * handful of specific labels we would like to change.
	 */
	private changeFirebaseAuthTextLabels() {
		if (!this.authContainer) {
			return;
		}

		// Create an observer instance linked to the callback function
		const observer = new MutationObserver(
			(mutationList: MutationRecord[], _observer: MutationObserver) => {
				if (!this.authContainer) {
					return;
				}

				let changedDom = false;
				mutationList.map((mr: MutationRecord) => {
					if (
						mr.addedNodes.length > 0 ||
						mr.removedNodes.length > 0
					) {
						changedDom = true;
					}
				});

				// Performance optimization: if we have not
				// added or removed elements, do not continue
				if (!changedDom) {
					return;
				}

				// Fragile. Could be made a bit more robust with some more specific
				// string matching on the content of firebaseui-text
				const uiText =
					this.authContainer?.querySelector(".firebaseui-text");
				if (uiText) {
					uiText.textContent =
						"Sounds.Studio is no longer allowing sign ups.";
				}
				// remove "Trouble singing up?" because it's casuing issues
				// we have a customer support link in the footer for any issues
				const fbUilinkText =
					this.authContainer?.querySelector(".firebaseui-link ");
				if (fbUilinkText) {
					fbUilinkText.textContent = "";
				}
			}
		);

		// Options for the observer (which mutations to observe)
		const config = { attributes: true, childList: true, subtree: true };

		// Start observing the target node for configured mutations
		observer.observe(this.authContainer, config);
	}

	render() {
		return html`
			<div id="container">
				<div class="signIn">Sign in to continue</div>
				<div id="auth-container"></div>
				<div id="spacer"></div>
				<div id="footer">
					<div id="cs">
						<a href=${FRESH_DESK_SUPPORT} target="_blank"
							>Contact Support</a
						>
					</div>
				</div>
			</div>
		`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		"sign-in-auth": SignInAuth;
	}
}
