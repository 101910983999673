import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";
import styles from "./header.scss";
import "./logo.js";

@customElement("tombstone-header")
class TombstoneHeader extends LitElement {
	static readonly styles = styles;

	render() {
		return html`
			<div id="container">
				<div id="logo">
					<tombstone-logo></tombstone-logo>
				</div>
			</div>
		`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		"tombstone-header": TombstoneHeader;
	}
}
