import {css} from "lit-element/lit-element.js";
export default css`#container {
  display: inline-block;
}
#container.disabled {
  pointer-events: none;
  opacity: 0.2;
}
#container a {
  text-decoration: none;
}
#container button {
  padding: 16px;
  width: 320px;
  appearance: none;
  border: none;
  background-color: #2a2a2a;
  color: #f5f4f3;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  text-align: center;
  transition: opacity 0.2s;
}
#container button .spacer {
  flex: 1;
}
#container button svg {
  margin-right: 16px;
  width: 20px;
}
#container button:hover {
  background-color: black;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9jbGllbnQvc3JjL2Rvd25sb2FkIiwic291cmNlcyI6WyJkb3dubG9hZC1idXR0b24uc2NzcyIsIi4uL2NvbW1vbi9jb21tb24uc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNDOztBQUVBO0VBQ0M7RUFDQTs7QUFHRDtFQUNDOztBQUdEO0VBQ0MsU0NOYTtFRE9iO0VBQ0E7RUFDQTtFQUNBLGtCQ2hCVTtFRGlCVixPQ2xCUztFRG1CVDtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNDOztBQUdEO0VBQ0MsY0N6Qlk7RUQwQlo7O0FBR0Q7RUFDQyIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgXCIuLi9jb21tb24vY29tbW9uLnNjc3NcIjtcbiNjb250YWluZXIge1xuXHRkaXNwbGF5OiBpbmxpbmUtYmxvY2s7XG5cblx0Ji5kaXNhYmxlZCB7XG5cdFx0cG9pbnRlci1ldmVudHM6IG5vbmU7XG5cdFx0b3BhY2l0eTogMC4yO1xuXHR9XG5cblx0YSB7XG5cdFx0dGV4dC1kZWNvcmF0aW9uOiBub25lO1xuXHR9XG5cblx0YnV0dG9uIHtcblx0XHRwYWRkaW5nOiAkc21hbGwtbWFyZ2luO1xuXHRcdHdpZHRoOiAzMjBweDtcblx0XHRhcHBlYXJhbmNlOiBub25lO1xuXHRcdGJvcmRlcjogbm9uZTtcblx0XHRiYWNrZ3JvdW5kLWNvbG9yOiAkZGFyay1ncmF5O1xuXHRcdGNvbG9yOiAkYmctY29sb3I7XG5cdFx0Ym9yZGVyLXJhZGl1czogNHB4O1xuXHRcdGN1cnNvcjogcG9pbnRlcjtcblx0XHRmb250LXNpemU6IDEuMmVtO1xuXHRcdGRpc3BsYXk6IGZsZXg7XG5cdFx0YWxpZ24taXRlbXM6IGNlbnRlcjtcblx0XHR0ZXh0LWFsaWduOiBjZW50ZXI7XG5cdFx0dHJhbnNpdGlvbjogb3BhY2l0eSAwLjJzO1xuXG5cdFx0LnNwYWNlciB7XG5cdFx0XHRmbGV4OiAxO1xuXHRcdH1cblxuXHRcdHN2ZyB7XG5cdFx0XHRtYXJnaW4tcmlnaHQ6ICRzbWFsbC1tYXJnaW47XG5cdFx0XHR3aWR0aDogMjBweDtcblx0XHR9XG5cblx0XHQmOmhvdmVyIHtcblx0XHRcdGJhY2tncm91bmQtY29sb3I6IGJsYWNrO1xuXHRcdH1cblx0fVxufVxuIiwiJGZnLWNvbG9yOiAjMmEyYTJhO1xuJGJnLWNvbG9yOiAjZjVmNGYzO1xuJGRhcmstZ3JheTogIzJhMmEyYTtcbiRsaWdodC1ncmF5OiAjYmZiZWJmO1xuJGdyYXk6ICM5ZTljOTg7XG5cbiRsYXJnZS1tYXJnaW46IDQ4cHg7XG4kbWVkaXVtLW1hcmdpbjogMzJweDtcbiRzbWFsbC1tYXJnaW46IDE2cHg7XG4keC1zbWFsbC1tYXJnaW46IDhweDtcbiJdfQ== */`;
