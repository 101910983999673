import {css} from "lit-element/lit-element.js";
export default css`#container {
  max-height: 0px;
  overflow: hidden;
  background-color: #2a2a2a;
  color: #f5f4f3;
  line-height: 1.5em;
  text-align: center;
  text-decoration: none;
}
#container:hover {
  background-color: #9e9c98;
  color: #2a2a2a;
}
#container:hover #arrow {
  transform: translateX(10px);
}
#container.notify {
  animation: expand 0.8s 1.2s cubic-bezier(0, 0, 0.5, 1) forwards;
}
#container #text {
  margin: 8px;
}
#container #text #arrow {
  margin-left: 10px;
  position: absolute;
  transition: transform 0.4s;
}

@keyframes expand {
  0% {
    max-height: 0px;
  }
  100% {
    max-height: 60px;
  }
}
a {
  text-decoration: none;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9jbGllbnQvc3JjL2xhbmRpbmciLCJzb3VyY2VzIjpbImJhbm5lci5zY3NzIiwiLi4vY29tbW9uL2NvbW1vbi5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0M7RUFDQTtFQUNBLGtCQ0hXO0VESVgsT0NMVTtFRE1WO0VBQ0E7RUFDQTs7QUFFQTtFQUNDLGtCQ1JLO0VEU0wsT0NYVTs7QURhVjtFQUNDOztBQUlGO0VBQ0M7O0FBR0Q7RUFDQyxRQ2hCZTs7QURrQmY7RUFDQztFQUNBO0VBQ0E7OztBQUtIO0VBQ0M7SUFDQzs7RUFFRDtJQUNDOzs7QUFJRjtFQUNDIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCBcIi4uL2NvbW1vbi9jb21tb24uc2Nzc1wiO1xuXG4jY29udGFpbmVyIHtcblx0bWF4LWhlaWdodDogMHB4O1xuXHRvdmVyZmxvdzogaGlkZGVuO1xuXHRiYWNrZ3JvdW5kLWNvbG9yOiAkZGFyay1ncmF5O1xuXHRjb2xvcjogJGJnLWNvbG9yO1xuXHRsaW5lLWhlaWdodDogMS41ZW07XG5cdHRleHQtYWxpZ246IGNlbnRlcjtcblx0dGV4dC1kZWNvcmF0aW9uOiBub25lO1xuXG5cdCY6aG92ZXIge1xuXHRcdGJhY2tncm91bmQtY29sb3I6ICRncmF5O1xuXHRcdGNvbG9yOiAkZGFyay1ncmF5O1xuXG5cdFx0I2Fycm93IHtcblx0XHRcdHRyYW5zZm9ybTogdHJhbnNsYXRlWCgxMHB4KTtcblx0XHR9XG5cdH1cblxuXHQmLm5vdGlmeSB7XG5cdFx0YW5pbWF0aW9uOiBleHBhbmQgMC44cyAxLjJzIGN1YmljLWJlemllcigwLCAwLCAwLjUsIDEpIGZvcndhcmRzO1xuXHR9XG5cblx0I3RleHQge1xuXHRcdG1hcmdpbjogJHgtc21hbGwtbWFyZ2luO1xuXG5cdFx0I2Fycm93IHtcblx0XHRcdG1hcmdpbi1sZWZ0OiAxMHB4O1xuXHRcdFx0cG9zaXRpb246IGFic29sdXRlO1xuXHRcdFx0dHJhbnNpdGlvbjogdHJhbnNmb3JtIDAuNHM7XG5cdFx0fVxuXHR9XG59XG5cbkBrZXlmcmFtZXMgZXhwYW5kIHtcblx0MCUge1xuXHRcdG1heC1oZWlnaHQ6IDBweDtcblx0fVxuXHQxMDAlIHtcblx0XHRtYXgtaGVpZ2h0OiA2MHB4O1xuXHR9XG59XG5cbmEge1xuXHR0ZXh0LWRlY29yYXRpb246IG5vbmU7XG59XG4iLCIkZmctY29sb3I6ICMyYTJhMmE7XG4kYmctY29sb3I6ICNmNWY0ZjM7XG4kZGFyay1ncmF5OiAjMmEyYTJhO1xuJGxpZ2h0LWdyYXk6ICNiZmJlYmY7XG4kZ3JheTogIzllOWM5ODtcblxuJGxhcmdlLW1hcmdpbjogNDhweDtcbiRtZWRpdW0tbWFyZ2luOiAzMnB4O1xuJHNtYWxsLW1hcmdpbjogMTZweDtcbiR4LXNtYWxsLW1hcmdpbjogOHB4O1xuIl19 */`;
