import { Router } from "@lit-labs/router";
import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";
import "./common/footer.js";
import "./common/header.js";
import "./download/main.js";
import "./landing/main.js";
import styles from "./main.scss";
import "./sign-in/main.js";
import { afterShutDownDate } from "./common/date.js";
import "urlpattern-polyfill";

@customElement("tombstone-router")
class TombstoneRouter extends LitElement {
	private async rejectRouteIfAfterShutdown() {
		if (afterShutDownDate()) {
			await this.routes.goto("/");
			return false;
		}
		return true;
	}

	private routes = new Router(this, [
		{
			path: "/",
			render: () => html` <landing-main><slot></slot></landing-main> `,
		},
		{
			path: "/sign-in",
			render: () => html`<sign-in-main></sign-in-main> `,
			enter: () => this.rejectRouteIfAfterShutdown(),
		},
		{
			path: "/download",
			render: () => html`<download-main></download-main>`,
			enter: () => this.rejectRouteIfAfterShutdown(),
		},
	]);

	static readonly styles = styles;

	render() {
		return html`
			<div id="container">
				<header>
					<tombstone-header></tombstone-header>
				</header>
				<div id="main">${this.routes.outlet()}</div>
				<footer>
					<tombstone-footer></tombstone-footer>
				</footer>
			</div>
		`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		"tombstone-router": TombstoneRouter;
	}
}
