import {css} from "lit-element/lit-element.js";
export default css`#container {
  display: inline-block;
}
#container button {
  width: 200px;
  appearance: none;
  background-color: transparent;
  color: #9e9c98;
  border: none;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
}
#container button:hover {
  color: #2a2a2a;
  background-color: #e0e0e0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9jbGllbnQvc3JjL2Rvd25sb2FkIiwic291cmNlcyI6WyJzaWduLW91dC5zY3NzIiwiLi4vY29tbW9uL2NvbW1vbi5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0M7O0FBRUE7RUFDQztFQUVBO0VBQ0E7RUFDQSxPQ05LO0VET0w7RUFDQTtFQUNBLFNDSmU7RURLZjs7QUFFQTtFQUNDLE9DZlM7RURnQlQiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IFwiLi4vY29tbW9uL2NvbW1vbi5zY3NzXCI7XG5cbiNjb250YWluZXIge1xuXHRkaXNwbGF5OiBpbmxpbmUtYmxvY2s7XG5cblx0YnV0dG9uIHtcblx0XHR3aWR0aDogMjAwcHg7XG5cblx0XHRhcHBlYXJhbmNlOiBub25lO1xuXHRcdGJhY2tncm91bmQtY29sb3I6IHRyYW5zcGFyZW50O1xuXHRcdGNvbG9yOiAkZ3JheTtcblx0XHRib3JkZXI6IG5vbmU7XG5cdFx0Ym9yZGVyLXJhZGl1czogNHB4O1xuXHRcdHBhZGRpbmc6ICR4LXNtYWxsLW1hcmdpbjtcblx0XHRjdXJzb3I6IHBvaW50ZXI7XG5cblx0XHQmOmhvdmVyIHtcblx0XHRcdGNvbG9yOiAkZGFyay1ncmF5O1xuXHRcdFx0YmFja2dyb3VuZC1jb2xvcjogI2UwZTBlMDtcblx0XHR9XG5cdH1cbn1cbiIsIiRmZy1jb2xvcjogIzJhMmEyYTtcbiRiZy1jb2xvcjogI2Y1ZjRmMztcbiRkYXJrLWdyYXk6ICMyYTJhMmE7XG4kbGlnaHQtZ3JheTogI2JmYmViZjtcbiRncmF5OiAjOWU5Yzk4O1xuXG4kbGFyZ2UtbWFyZ2luOiA0OHB4O1xuJG1lZGl1bS1tYXJnaW46IDMycHg7XG4kc21hbGwtbWFyZ2luOiAxNnB4O1xuJHgtc21hbGwtbWFyZ2luOiA4cHg7XG4iXX0= */`;
