import "./init.js";
import { getAuth } from "firebase/auth";

export async function getAuthenticatedUser() {
	if (getAuth().currentUser) {
		return getAuth().currentUser;
	}
	// give it a minute for the user to authenticate
	await Promise.race([
		new Promise<void>((done, error) => {
			getAuth().onAuthStateChanged(
				(user) => {
					done();
				},
				(e) => error(`Firebase Auth Error: ${e}`)
			);
		}),
		await new Promise<void>((done) => setTimeout(done, 1000)),
	]);
	return getAuth().currentUser;
}

/**
 * Sign out
 */
export async function signOut() {
	await getAuth().signOut();
}
