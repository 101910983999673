import {css} from "lit-element/lit-element.js";
export default css`#container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
#container .spacer {
  flex: 1;
}
#container #center {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  justify-content: flex-end;
  margin: 32px 0;
}
#container #center #copy {
  min-width: 40%;
  max-width: 400px;
  padding: 0 96px;
}
@media screen and (max-width: 700px) {
  #container #center #copy {
    padding: 0 64px;
  }
}
#container #center #copy #rip {
  justify-content: center;
  text-align: center;
  font-size: 22px;
}
#container #center landing-canvas-image {
  max-width: 700px;
  flex: 2;
  margin-right: -48px;
  opacity: 0.7;
  overflow: hidden;
}
@media screen and (max-width: 700px) {
  #container #center landing-canvas-image {
    display: none;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9jbGllbnQvc3JjL2xhbmRpbmciLCJzb3VyY2VzIjpbIm1haW4uc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNDO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBSUE7RUFDQzs7QUFHRDtFQUNDO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDQztFQUNBO0VBQ0E7O0FBRUE7RUFMRDtJQU1FOzs7QUFHRDtFQUNDO0VBQ0E7RUFDQTs7QUFJRjtFQUNDO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFQRDtJQVFFIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCBcIi4uL2NvbW1vbi9jb21tb24uc2Nzc1wiO1xuXG4jY29udGFpbmVyIHtcblx0cG9zaXRpb246IHJlbGF0aXZlO1xuXHRoZWlnaHQ6IDEwMCU7XG5cdHdpZHRoOiAxMDAlO1xuXHRkaXNwbGF5OiBmbGV4O1xuXHRmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuXG5cdCRzbWFsbC1zY3JlZW46IDcwMHB4O1xuXG5cdC5zcGFjZXIge1xuXHRcdGZsZXg6IDE7XG5cdH1cblxuXHQjY2VudGVyIHtcblx0XHRkaXNwbGF5OiBmbGV4O1xuXHRcdGZsZXgtZGlyZWN0aW9uOiByb3c7XG5cdFx0b3ZlcmZsb3c6IGhpZGRlbjtcblx0XHRqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtZW5kO1xuXHRcdG1hcmdpbjogJG1lZGl1bS1tYXJnaW4gMDtcblxuXHRcdCNjb3B5IHtcblx0XHRcdG1pbi13aWR0aDogNDAlO1xuXHRcdFx0bWF4LXdpZHRoOiA0MDBweDtcblx0XHRcdHBhZGRpbmc6IDAgJGxhcmdlLW1hcmdpbiAqIDI7XG5cblx0XHRcdEBtZWRpYSBzY3JlZW4gYW5kIChtYXgtd2lkdGg6ICRzbWFsbC1zY3JlZW4pIHtcblx0XHRcdFx0cGFkZGluZzogMCAkbWVkaXVtLW1hcmdpbiAqIDI7XG5cdFx0XHR9XG5cblx0XHRcdCNyaXAge1xuXHRcdFx0XHRqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcblx0XHRcdFx0dGV4dC1hbGlnbjogY2VudGVyO1xuXHRcdFx0XHRmb250LXNpemU6IDIycHg7XG5cdFx0XHR9XG5cdFx0fVxuXG5cdFx0bGFuZGluZy1jYW52YXMtaW1hZ2Uge1xuXHRcdFx0bWF4LXdpZHRoOiA3MDBweDtcblx0XHRcdGZsZXg6IDI7XG5cdFx0XHRtYXJnaW4tcmlnaHQ6IC0kbGFyZ2UtbWFyZ2luO1xuXHRcdFx0b3BhY2l0eTogMC43O1xuXHRcdFx0b3ZlcmZsb3c6IGhpZGRlbjtcblxuXHRcdFx0QG1lZGlhIHNjcmVlbiBhbmQgKG1heC13aWR0aDogJHNtYWxsLXNjcmVlbikge1xuXHRcdFx0XHRkaXNwbGF5OiBub25lO1xuXHRcdFx0fVxuXHRcdH1cblx0fVxufVxuIl19 */`;
