import styles from "./main.scss";
import { LitElement, PropertyValueMap, html } from "lit";
import { customElement, state } from "lit/decorators.js";
import "./auth.js";
import { User } from "firebase/auth";
import { getAuthenticatedUser } from "../firebase/auth";

@customElement("sign-in-main")
class TombstoneSignIn extends LitElement {
	@state() private user: User | null = null;

	private async loadUser() {
		this.user = await getAuthenticatedUser();
	}

	protected async firstUpdated() {
		// if logged in send to download page
		this.loadUser();
	}

	protected updated(changedProperties: PropertyValueMap<any>): void {
		if (changedProperties.has("user") && this.user) {
			window.location.pathname = "/download";
		}
	}

	static readonly styles = styles;

	render() {
		return html` <div id="container">
			<div class="spacer"></div>
			<sign-in-auth></sign-in-auth>
			<div class="spacer"></div>
		</div>`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		"sign-in-main": TombstoneSignIn;
	}
}
