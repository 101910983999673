import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";
import styles from "./footer.scss";
import { PRIVACY_POLICY } from "./links";

@customElement("tombstone-footer")
class TombstoneFooter extends LitElement {
	static readonly styles = styles;

	render() {
		return html`
			<div id="container">
				<div id="content">
					<div id="copyright">
						&copy; 2024 Never Before Heard Sounds, Inc.
					</div>
					<div class="spacer"></div>
					<div class="link">
						<a href=${PRIVACY_POLICY}>Privacy Policy</a>
					</div>
				</div>
			</div>
		`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		"tombstone-footer": TombstoneFooter;
	}
}
