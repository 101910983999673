import {css} from "lit-element/lit-element.js";
export default css`#container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
#container .spacer {
  flex: 1;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9jbGllbnQvc3JjL3NpZ24taW4iLCJzb3VyY2VzIjpbIm1haW4uc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNDO0VBQ0E7RUFDQTs7QUFFQTtFQUNDIiwic291cmNlc0NvbnRlbnQiOlsiI2NvbnRhaW5lciB7XG5cdGRpc3BsYXk6IGZsZXg7XG5cdGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG5cdGhlaWdodDogMTAwJTtcblxuXHQuc3BhY2VyIHtcblx0XHRmbGV4OiAxO1xuXHR9XG59XG4iXX0= */`;
