import styles from "./logo.scss";
import { LitElement, html } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

/**
 * Display the product name in logo form or plain text.
 */
@customElement("tombstone-logo")
export class TombstoneLogo extends LitElement {
	/**
	 * If the logo should be on one or two lines.
	 */
	@property({ type: Boolean }) lineBreak = false;

	/**
	 * Plaintext just shows the name of the product in the current font and
	 * styling with minimal or no styling.
	 */
	@property({ type: Boolean }) plainText = false;

	static readonly styles = styles;

	protected render() {
		return html`<span
			id="container"
			class=${classMap({
				lineBreak: this.lineBreak,
				plainText: this.plainText,
			})}
		>
			${when(
				!this.lineBreak,
				() => html`
					<svg
						viewBox="0 0 213 28"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M48.0232 7.60287V24.199H44.1396L43.7745 22.2407H43.509C42.3362 23.7675 40.7098 24.531 38.6297 24.531C36.6382 24.531 35.056 23.9224 33.8832 22.7054C32.7326 21.4883 32.1572 19.8066 32.1572 17.6601V7.60287H36.4058V16.7971C36.4058 18.1027 36.7378 19.1095 37.4016 19.8176C38.0655 20.5036 38.9285 20.8466 39.9906 20.8466C41.0749 20.8466 41.9711 20.4926 42.6792 19.7845C43.4094 19.0542 43.7745 17.9921 43.7745 16.598V7.60287H48.0232Z"
							fill="currentColor"
						/>
						<path
							d="M51.7679 24.199V7.60287H55.6514L56.0165 9.56122H56.2821C57.4549 8.03437 59.0813 7.27095 61.1613 7.27095C63.1529 7.27095 64.724 7.87947 65.8746 9.09653C67.0474 10.3136 67.6338 11.9953 67.6338 14.1418V24.199H63.3852V15.0048C63.3852 13.6992 63.0533 12.7034 62.3895 12.0175C61.7256 11.3093 60.8737 10.9553 59.8337 10.9553C58.7494 10.9553 57.8421 11.3204 57.1119 12.0506C56.3816 12.7587 56.0165 13.8098 56.0165 15.2039V24.199H51.7679Z"
							fill="currentColor"
						/>
						<path
							d="M82.7339 0.631592H86.9825V24.1981H83.5305L83.1654 22.2398H82.8667C81.716 23.7666 80.0674 24.5301 77.921 24.5301C75.6639 24.5301 73.7941 23.7334 72.3115 22.1402C70.8511 20.547 70.1208 18.4559 70.1208 15.8669C70.1208 13.2336 70.8621 11.1425 72.3447 9.59352C73.8273 8.04454 75.6861 7.27005 77.921 7.27005C80.0011 7.27005 81.6054 7.90071 82.7339 9.16202V0.631592ZM75.5312 19.4848C76.3056 20.3921 77.3235 20.8457 78.5849 20.8457C79.8462 20.8457 80.853 20.3921 81.6054 19.4848C82.3577 18.5776 82.7339 17.3716 82.7339 15.8669C82.7339 14.4064 82.3577 13.2225 81.6054 12.3153C80.853 11.408 79.8462 10.9544 78.5849 10.9544C77.3235 10.9544 76.3056 11.408 75.5312 12.3153C74.7567 13.2225 74.3694 14.4064 74.3694 15.8669C74.3694 17.3495 74.7567 18.5554 75.5312 19.4848Z"
							fill="currentColor"
						/>
						<path
							d="M97.3587 24.531C95.0795 24.531 93.3092 24.0331 92.0479 23.0373C90.7866 22.0194 90.1006 20.6364 89.99 18.8883H94.0726C94.2939 20.5479 95.3782 21.3777 97.3255 21.3777C98.0336 21.3777 98.6089 21.2449 99.0515 20.9794C99.5162 20.6917 99.7485 20.2602 99.7485 19.6849C99.7485 19.0874 99.5051 18.6338 99.0183 18.324C98.5536 17.9921 97.8234 17.6933 96.8276 17.4278L95.2676 17.0295C94.6037 16.8746 94.0284 16.7086 93.5416 16.5316C93.0769 16.3324 92.59 16.0669 92.0811 15.735C91.5721 15.3809 91.1849 14.9273 90.9194 14.3741C90.6538 13.7988 90.5211 13.1239 90.5211 12.3494C90.5211 10.7561 91.1185 9.51696 92.3134 8.63183C93.5084 7.72458 94.9799 7.27095 96.728 7.27095C98.8523 7.27095 100.479 7.7467 101.607 8.69822C102.736 9.64973 103.366 10.8668 103.499 12.3494H99.4166C99.2396 11.0659 98.3544 10.4242 96.7612 10.4242C96.0974 10.4242 95.5663 10.5791 95.168 10.8889C94.7697 11.1766 94.5705 11.586 94.5705 12.117C94.5705 12.6481 94.7697 13.0353 95.168 13.2788C95.5884 13.5 96.2965 13.7435 97.2923 14.009L98.8523 14.4073C100.446 14.8056 101.663 15.4031 102.503 16.1997C103.366 16.9963 103.798 18.1248 103.798 19.5853C103.798 21.2228 103.189 22.462 101.972 23.3028C100.777 24.1216 99.2396 24.531 97.3587 24.531Z"
							fill="currentColor"
						/>
						<path
							d="M108.879 24.2352C108.333 24.2352 107.857 24.0426 107.452 23.6575C107.064 23.2548 106.87 22.7822 106.87 22.2395C106.87 21.6793 107.064 21.2066 107.452 20.8215C107.857 20.4188 108.333 20.2175 108.879 20.2175C109.443 20.2175 109.919 20.4188 110.306 20.8215C110.694 21.2066 110.888 21.6793 110.888 22.2395C110.888 22.7822 110.694 23.2548 110.306 23.6575C109.919 24.0426 109.443 24.2352 108.879 24.2352Z"
							fill="currentColor"
						/>
						<mask
							id="mask0_5661_43050"
							style="mask-type:alpha"
							maskUnits="userSpaceOnUse"
							x="20"
							y="3"
							width="9"
							height="25"
						>
							<rect
								width="8.52632"
								height="24.1579"
								transform="matrix(-1 0 0 1 28.9092 3.47375)"
								fill="#F5F4F3"
							/>
						</mask>
						<g mask="url(#mask0_5661_43050)">
							<path
								d="M20.4239 24.5301C17.9234 24.5301 15.8323 23.7225 14.1506 22.1071C12.4909 20.4696 11.6611 18.3896 11.6611 15.867C11.6611 13.3443 12.4909 11.2864 14.1506 9.69318C15.8102 8.07782 17.9013 7.27014 20.4239 7.27014C22.9465 7.27014 25.0376 8.07782 26.6973 9.69318C28.3569 11.2864 29.1867 13.3443 29.1867 15.867C29.1867 18.4117 28.3569 20.4917 26.6973 22.1071C25.0376 23.7225 22.9465 24.5301 20.4239 24.5301ZM20.4239 20.8458C21.7737 20.8458 22.8691 20.37 23.7099 19.4185C24.5508 18.467 24.9713 17.2832 24.9713 15.867C24.9713 14.4729 24.5508 13.3111 23.7099 12.3818C22.8691 11.4302 21.7737 10.9545 20.4239 10.9545C19.0741 10.9545 17.9787 11.4302 17.1379 12.3818C16.3191 13.3111 15.9098 14.4729 15.9098 15.867C15.9098 17.2832 16.3302 18.467 17.1711 19.4185C18.0119 20.37 19.0962 20.8458 20.4239 20.8458Z"
								fill="currentColor"
							/>
						</g>
						<path
							d="M17.2172 18.5C17.2172 20.6686 16.4427 22.3282 14.8937 23.4789C13.3669 24.6295 11.4528 25.2049 9.15148 25.2049C6.45183 25.2049 4.34965 24.541 2.84494 23.2133C1.36235 21.8635 0.576794 20.049 0.488281 17.7698H4.80328C4.91392 18.9426 5.33436 19.8609 6.06459 20.5247C6.81695 21.1665 7.84591 21.4873 9.15148 21.4873C10.2136 21.4873 11.0877 21.255 11.7737 20.7903C12.4596 20.3035 12.8026 19.5843 12.8026 18.6328C12.8026 18.0796 12.6809 17.5928 12.4375 17.1723C12.2162 16.7519 11.8511 16.3978 11.3422 16.1102C10.8553 15.8004 10.4128 15.568 10.0145 15.4131C9.61617 15.2582 9.07403 15.0812 8.38805 14.8821L7.06036 14.4837C3.16579 13.3109 1.21851 11.1534 1.21851 8.01124C1.21851 5.97545 1.95981 4.34903 3.4424 3.13197C4.92499 1.91492 6.80589 1.3064 9.08509 1.3064C11.4307 1.3064 13.3226 1.94811 14.761 3.23155C16.1993 4.49286 16.9517 6.13035 17.0181 8.14401H12.7031C12.5924 7.1925 12.2384 6.44014 11.6409 5.88694C11.0434 5.3116 10.2026 5.02394 9.11828 5.02394C8.07826 5.02394 7.23739 5.27841 6.59567 5.78736C5.95395 6.29631 5.63309 6.97122 5.63309 7.81209C5.63309 8.27678 5.73267 8.69722 5.93182 9.0734C6.1531 9.42745 6.48503 9.73724 6.92759 10.0028C7.37016 10.2462 7.76846 10.4343 8.12251 10.5671C8.49869 10.6998 8.99658 10.8658 9.61617 11.0649L10.7779 11.4301C11.3532 11.6071 11.8622 11.7841 12.3047 11.9611C12.7694 12.1382 13.3226 12.4258 13.9644 12.8241C14.6282 13.2003 15.1703 13.6318 15.5908 14.1186C16.0333 14.5833 16.4095 15.2029 16.7193 15.9774C17.0512 16.7298 17.2172 17.5706 17.2172 18.5Z"
							fill="currentColor"
						/>
						<path
							d="M132.523 18.2775C132.523 20.4194 131.758 22.0587 130.229 23.1952C128.72 24.3317 126.83 24.9 124.557 24.9C121.89 24.9 119.814 24.2443 118.328 22.9329C116.863 21.5997 116.087 19.8075 116 17.5562H120.262C120.371 18.7146 120.787 19.6217 121.508 20.2774C122.251 20.9112 123.267 21.2281 124.557 21.2281C125.606 21.2281 126.469 20.9986 127.147 20.5396C127.824 20.0588 128.163 19.3485 128.163 18.4086C128.163 17.8622 128.043 17.3814 127.802 16.9661C127.584 16.5508 127.223 16.2011 126.721 15.917C126.24 15.611 125.803 15.3815 125.409 15.2285C125.016 15.0755 124.48 14.9007 123.803 14.704L122.491 14.3106C118.645 13.1522 116.721 11.0212 116.721 7.91756C116.721 5.90677 117.453 4.30032 118.918 3.09822C120.382 1.89611 122.24 1.29506 124.491 1.29506C126.808 1.29506 128.677 1.9289 130.097 3.19657C131.518 4.44239 132.261 6.05976 132.327 8.0487H128.065C127.955 7.10887 127.606 6.36575 127.016 5.81934C126.426 5.25107 125.595 4.96694 124.524 4.96694C123.497 4.96694 122.666 5.21829 122.032 5.72099C121.399 6.22368 121.082 6.89031 121.082 7.72085C121.082 8.17984 121.18 8.59511 121.377 8.96667C121.595 9.31637 121.923 9.62236 122.36 9.88464C122.797 10.1251 123.191 10.3108 123.54 10.442C123.912 10.5731 124.404 10.737 125.016 10.9337L126.163 11.2944C126.732 11.4692 127.234 11.6441 127.671 11.8189C128.13 11.9938 128.677 12.2779 129.311 12.6713C129.966 13.0429 130.502 13.4691 130.917 13.9499C131.354 14.4089 131.726 15.0209 132.032 15.7859C132.36 16.529 132.523 17.3595 132.523 18.2775Z"
							fill="currentColor"
						/>
						<path
							d="M142.876 24.9C139.466 24.9 137.762 23.3045 137.762 20.1134V11.4911H134.254V8.17984H135.368C136.374 8.17984 137.084 7.99406 137.499 7.6225C137.936 7.22908 138.155 6.58432 138.155 5.6882V3.72112H141.958V8.17984H146.253V11.4911H141.958V19.5561C141.958 19.9277 141.991 20.2446 142.056 20.5069C142.144 20.7691 142.362 21.0205 142.712 21.2609C143.062 21.4795 143.543 21.5887 144.155 21.5887C144.657 21.5887 145.193 21.5123 145.761 21.3593V24.5394C144.712 24.7798 143.75 24.9 142.876 24.9Z"
							fill="currentColor"
						/>
						<path
							d="M164.362 8.17984V24.5722H160.526L160.166 22.6379H159.903C158.745 24.146 157.138 24.9 155.084 24.9C153.117 24.9 151.554 24.2989 150.396 23.0968C149.259 21.8947 148.691 20.2337 148.691 18.1136V8.17984H152.887V17.2612C152.887 18.5507 153.215 19.5452 153.871 20.2446C154.527 20.9221 155.379 21.2609 156.428 21.2609C157.499 21.2609 158.384 20.9112 159.084 20.2118C159.805 19.4905 160.166 18.4414 160.166 17.0645V8.17984H164.362Z"
							fill="currentColor"
						/>
						<path
							d="M179.837 1.29506H184.033V24.5722H180.623L180.263 22.6379H179.968C178.831 24.146 177.203 24.9 175.083 24.9C172.853 24.9 171.007 24.1132 169.542 22.5395C168.1 20.9658 167.378 18.9004 167.378 16.3432C167.378 13.7423 168.111 11.6769 169.575 10.1469C171.039 8.61696 172.875 7.85199 175.083 7.85199C177.137 7.85199 178.722 8.4749 179.837 9.72071V1.29506ZM172.722 19.9167C173.487 20.8128 174.493 21.2609 175.738 21.2609C176.984 21.2609 177.979 20.8128 178.722 19.9167C179.465 19.0206 179.837 17.8294 179.837 16.3432C179.837 14.9007 179.465 13.7314 178.722 12.8353C177.979 11.9391 176.984 11.4911 175.738 11.4911C174.493 11.4911 173.487 11.9391 172.722 12.8353C171.957 13.7314 171.575 14.9007 171.575 16.3432C171.575 17.8076 171.957 18.9988 172.722 19.9167Z"
							fill="currentColor"
						/>
						<path
							d="M189.785 6.08162C189.085 6.08162 188.474 5.83027 187.949 5.32757C187.446 4.82487 187.195 4.23475 187.195 3.5572C187.195 2.83594 187.446 2.23489 187.949 1.75405C188.474 1.25135 189.085 1 189.785 1C190.484 1 191.074 1.25135 191.555 1.75405C192.058 2.23489 192.309 2.83594 192.309 3.5572C192.309 4.23475 192.058 4.82487 191.555 5.32757C191.053 5.83027 190.462 6.08162 189.785 6.08162ZM187.687 24.5722V8.17984H191.883V24.5722H187.687Z"
							fill="currentColor"
						/>
						<path
							d="M203.535 24.9C201.065 24.9 199 24.1022 197.338 22.5067C195.699 20.8893 194.88 18.8348 194.88 16.3432C194.88 13.8516 195.699 11.8189 197.338 10.2453C198.978 8.64975 201.043 7.85199 203.535 7.85199C206.026 7.85199 208.092 8.64975 209.731 10.2453C211.37 11.8189 212.19 13.8516 212.19 16.3432C212.19 18.8567 211.37 20.9112 209.731 22.5067C208.092 24.1022 206.026 24.9 203.535 24.9ZM203.535 21.2609C204.868 21.2609 205.95 20.791 206.78 19.8512C207.611 18.9113 208.026 17.742 208.026 16.3432C208.026 14.9663 207.611 13.8188 206.78 12.9008C205.95 11.961 204.868 11.4911 203.535 11.4911C202.201 11.4911 201.12 11.961 200.289 12.9008C199.48 13.8188 199.076 14.9663 199.076 16.3432C199.076 17.742 199.491 18.9113 200.322 19.8512C201.152 20.791 202.223 21.2609 203.535 21.2609Z"
							fill="currentColor"
						/>
					</svg>
				`,
				() => html`
					<svg
						viewBox="0 0 183 66"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M42.6035 56.428C42.6035 57.9067 42.0708 59.0384 41.0055 59.823C39.9553 60.6077 38.6389 61 37.0561 61C35.1993 61 33.7534 60.5473 32.7185 59.642C31.6988 58.7215 31.1585 57.4842 31.0977 55.93H34.0654C34.1415 56.7298 34.4307 57.356 34.9329 57.8086C35.4504 58.2462 36.1581 58.465 37.0561 58.465C37.7866 58.465 38.3877 58.3066 38.8596 57.9897C39.3314 57.6578 39.5673 57.1674 39.5673 56.5185C39.5673 56.1413 39.4835 55.8093 39.3161 55.5226C39.1639 55.2359 38.9128 54.9945 38.5628 54.7984C38.2279 54.5871 37.9236 54.4287 37.6496 54.323C37.3757 54.2174 37.0028 54.0967 36.531 53.9609L35.6178 53.6893C32.9392 52.8896 31.5999 51.4184 31.5999 49.2757C31.5999 47.8875 32.1097 46.7785 33.1294 45.9486C34.1491 45.1187 35.4428 44.7037 37.0104 44.7037C38.6236 44.7037 39.9249 45.1413 40.9142 46.0165C41.9034 46.8765 42.4209 47.9931 42.4665 49.3663H39.4988C39.4227 48.7174 39.1792 48.2044 38.7682 47.8272C38.3573 47.4348 37.779 47.2387 37.0332 47.2387C36.3179 47.2387 35.7396 47.4122 35.2982 47.7593C34.8568 48.1063 34.6362 48.5665 34.6362 49.1399C34.6362 49.4568 34.7047 49.7435 34.8416 50C34.9938 50.2414 35.2221 50.4527 35.5265 50.6337C35.8309 50.7997 36.1048 50.928 36.3483 51.0185C36.6071 51.1091 36.9495 51.2222 37.3757 51.358L38.1747 51.607C38.5704 51.7277 38.9204 51.8484 39.2248 51.9691C39.5444 52.0898 39.9249 52.286 40.3663 52.5576C40.8229 52.8141 41.1957 53.1084 41.4849 53.4403C41.7893 53.7572 42.048 54.1797 42.2611 54.7078C42.4894 55.2209 42.6035 55.7942 42.6035 56.428Z"
							fill="currentColor"
						/>
						<path
							d="M49.8126 61.0001C47.4384 61.0001 46.2513 59.8986 46.2513 57.6956V51.7429H43.8086V49.4569H44.5848C45.2849 49.4569 45.7795 49.3286 46.0687 49.0721C46.3731 48.8005 46.5253 48.3554 46.5253 47.7367V46.3787H49.1734V49.4569H52.164V51.7429H49.1734V57.3108C49.1734 57.5673 49.1963 57.7861 49.2419 57.9672C49.3028 58.1482 49.455 58.3218 49.6985 58.4878C49.942 58.6386 50.2768 58.7141 50.703 58.7141C51.053 58.7141 51.4259 58.6613 51.8216 58.5557V60.7511C51.0911 60.9171 50.4214 61.0001 49.8126 61.0001Z"
							fill="currentColor"
						/>
						<path
							d="M64.7736 49.4568V60.7737H62.1026L61.8515 59.4383H61.6688C60.8622 60.4794 59.7436 61 58.313 61C56.9432 61 55.855 60.585 55.0484 59.7551C54.257 58.9252 53.8613 57.7785 53.8613 56.3148V49.4568H56.7834V55.7263C56.7834 56.6166 57.0117 57.3032 57.4683 57.786C57.9249 58.2538 58.5184 58.4877 59.2489 58.4877C59.9947 58.4877 60.6111 58.2462 61.0981 57.7634C61.6003 57.2654 61.8515 56.5412 61.8515 55.5905V49.4568H64.7736Z"
							fill="currentColor"
						/>
						<path
							d="M75.5491 44.7037H78.4712V60.7737H76.097L75.8459 59.4383H75.6404C74.849 60.4794 73.7152 61 72.2389 61C70.6865 61 69.4005 60.4568 68.3808 59.3704C67.3763 58.2839 66.874 56.858 66.874 55.0926C66.874 53.297 67.3839 51.8711 68.4036 50.8148C69.4233 49.7586 70.7017 49.2305 72.2389 49.2305C73.6695 49.2305 74.7729 49.6605 75.5491 50.5206V44.7037ZM70.5952 57.5597C71.1279 58.1783 71.828 58.4877 72.6955 58.4877C73.563 58.4877 74.2554 58.1783 74.7729 57.5597C75.2904 56.941 75.5491 56.1187 75.5491 55.0926C75.5491 54.0967 75.2904 53.2894 74.7729 52.6708C74.2554 52.0521 73.563 51.7428 72.6955 51.7428C71.828 51.7428 71.1279 52.0521 70.5952 52.6708C70.0625 53.2894 69.7962 54.0967 69.7962 55.0926C69.7962 56.1036 70.0625 56.9259 70.5952 57.5597Z"
							fill="currentColor"
						/>
						<path
							d="M82.4763 48.0082C81.9893 48.0082 81.5632 47.8347 81.198 47.4877C80.8479 47.1406 80.6729 46.7332 80.6729 46.2654C80.6729 45.7675 80.8479 45.3525 81.198 45.0206C81.5632 44.6735 81.9893 44.5 82.4763 44.5C82.9633 44.5 83.3743 44.6735 83.7092 45.0206C84.0592 45.3525 84.2341 45.7675 84.2341 46.2654C84.2341 46.7332 84.0592 47.1406 83.7092 47.4877C83.3591 47.8347 82.9481 48.0082 82.4763 48.0082ZM81.0153 60.7737V49.4568H83.9373V60.7737H81.0153Z"
							fill="currentColor"
						/>
						<path
							d="M92.0513 61C90.3315 61 88.8932 60.4492 87.7366 59.3477C86.5951 58.2311 86.0244 56.8128 86.0244 55.0926C86.0244 53.3724 86.5951 51.9691 87.7366 50.8827C88.878 49.7812 90.3163 49.2305 92.0513 49.2305C93.7863 49.2305 95.2245 49.7812 96.366 50.8827C97.5074 51.9691 98.0781 53.3724 98.0781 55.0926C98.0781 56.8278 97.5074 58.2462 96.366 59.3477C95.2245 60.4492 93.7863 61 92.0513 61ZM92.0513 58.4877C92.9797 58.4877 93.733 58.1632 94.3113 57.5144C94.8897 56.8656 95.1789 56.0583 95.1789 55.0926C95.1789 54.142 94.8897 53.3498 94.3113 52.716C93.733 52.0672 92.9797 51.7428 92.0513 51.7428C91.1229 51.7428 90.3695 52.0672 89.7912 52.716C89.2281 53.3498 88.9465 54.142 88.9465 55.0926C88.9465 56.0583 89.2357 56.8656 89.814 57.5144C90.3924 58.1632 91.1381 58.4877 92.0513 58.4877Z"
							fill="currentColor"
						/>
						<path
							d="M178.517 39.1104C177.386 39.1104 176.4 38.7148 175.561 37.9237C174.758 37.0966 174.356 36.1256 174.356 35.0109C174.356 33.8601 174.758 32.8892 175.561 32.0981C176.4 31.271 177.386 30.8574 178.517 30.8574C179.685 30.8574 180.67 31.271 181.473 32.0981C182.276 32.8892 182.677 33.8601 182.677 35.0109C182.677 36.1256 182.276 37.0966 181.473 37.9237C180.67 38.7148 179.685 39.1104 178.517 39.1104Z"
							fill="currentColor"
						/>
						<path
							d="M77.5681 11.329V38.2994H71.1632L70.5611 35.1169H70.1231C68.1889 37.5982 65.5066 38.8388 62.0761 38.8388C58.7915 38.8388 56.1822 37.8499 54.248 35.8721C52.3502 33.8942 51.4014 31.1612 51.4014 27.6731V11.329H58.4083V26.2706C58.4083 28.3923 58.9558 30.0285 60.0506 31.1792C61.1455 32.294 62.5687 32.8514 64.3205 32.8514C66.1087 32.8514 67.5868 32.276 68.7546 31.1253C69.9589 29.9386 70.5611 28.2125 70.5611 25.947V11.329H77.5681Z"
							fill="currentColor"
						/>
						<path
							d="M83.7441 38.2994V11.329H90.1489L90.751 14.5115H91.189C93.1232 12.0302 95.8056 10.7896 99.2361 10.7896C102.521 10.7896 105.112 11.7785 107.009 13.7564C108.944 15.7342 109.911 18.4671 109.911 21.9552V38.2994H102.904V23.3577C102.904 21.236 102.356 19.6178 101.262 18.503C100.167 17.3523 98.7616 16.777 97.0464 16.777C95.2581 16.777 93.7619 17.3704 92.5575 18.5571C91.3532 19.7078 90.751 21.4159 90.751 23.6814V38.2994H83.7441Z"
							fill="currentColor"
						/>
						<path
							d="M135.643 0H142.65V38.2979H136.956L136.354 35.1154H135.862C133.964 37.5967 131.245 38.8374 127.705 38.8374C123.983 38.8374 120.899 37.5428 118.454 34.9536C116.045 32.3645 114.841 28.9662 114.841 24.7588C114.841 20.4795 116.063 17.0812 118.508 14.564C120.953 12.0468 124.019 10.7882 127.705 10.7882C131.135 10.7882 133.781 11.813 135.643 13.8628V0ZM123.764 30.6384C125.041 32.1127 126.72 32.8499 128.8 32.8499C130.88 32.8499 132.541 32.1127 133.781 30.6384C135.022 29.164 135.643 27.2041 135.643 24.7588C135.643 22.3854 135.022 20.4615 133.781 18.9872C132.541 17.5128 130.88 16.7756 128.8 16.7756C126.72 16.7756 125.041 17.5128 123.764 18.9872C122.486 20.4615 121.848 22.3854 121.848 24.7588C121.848 27.1682 122.486 29.128 123.764 30.6384Z"
							fill="currentColor"
						/>
						<path
							d="M159.762 38.8387C156.003 38.8387 153.084 38.0296 151.004 36.4114C148.923 34.7572 147.792 32.5097 147.61 29.6688H154.343C154.708 32.3658 156.496 33.7144 159.708 33.7144C160.875 33.7144 161.824 33.4987 162.554 33.0671C163.321 32.5997 163.704 31.8983 163.704 30.9634C163.704 29.9924 163.302 29.2553 162.499 28.7519C161.733 28.2125 160.529 27.727 158.886 27.2955L156.314 26.6481C155.219 26.3964 154.27 26.1268 153.467 25.8391C152.701 25.5154 151.898 25.0839 151.058 24.5445C150.219 23.9691 149.58 23.2318 149.142 22.3328C148.704 21.3979 148.485 20.3011 148.485 19.0424C148.485 16.4534 149.471 14.4396 151.442 13.0012C153.412 11.5268 155.839 10.7896 158.722 10.7896C162.226 10.7896 164.908 11.5628 166.769 13.1091C168.631 14.6554 169.671 16.6331 169.89 19.0424H163.156C162.864 16.9567 161.405 15.9139 158.777 15.9139C157.682 15.9139 156.806 16.1657 156.149 16.6692C155.492 17.1366 155.164 17.8019 155.164 18.665C155.164 19.528 155.492 20.1573 156.149 20.5529C156.843 20.9125 158.011 21.308 159.653 21.7395L162.226 22.3869C164.853 23.0342 166.861 24.0051 168.247 25.2997C169.671 26.5943 170.382 28.4281 170.382 30.8015C170.382 33.4626 169.379 35.4764 167.371 36.8429C165.401 38.1734 162.864 38.8387 159.762 38.8387Z"
							fill="currentColor"
						/>
						<mask
							id="mask0_5661_43012"
							style="mask-type:alpha"
							maskUnits="userSpaceOnUse"
							x="32"
							y="5"
							width="16"
							height="40"
						>
							<path
								d="M32.0912 5.58008H47.1924V44.6407H32.0912V5.58008Z"
								fill="#E5E5E5"
							/>
						</mask>
						<g mask="url(#mask0_5661_43012)">
							<path
								d="M32.0486 38.8387C27.9247 38.8387 24.4759 37.5262 21.7023 34.9011C18.9652 32.24 17.5967 28.8597 17.5967 24.7602C17.5967 20.6607 18.9652 17.3163 21.7023 14.7271C24.4394 12.1021 27.8882 10.7896 32.0486 10.7896C36.209 10.7896 39.6577 12.1021 42.3948 14.7271C45.1319 17.3163 46.5005 20.6607 46.5005 24.7602C46.5005 28.8957 45.1319 32.276 42.3948 34.9011C39.6577 37.5262 36.209 38.8387 32.0486 38.8387ZM32.0486 32.8512C34.2748 32.8512 36.0812 32.0782 37.468 30.5319C38.8548 28.9856 39.5482 27.0617 39.5482 24.7602C39.5482 22.4947 38.8548 20.6067 37.468 19.0963C36.0812 17.55 34.2748 16.777 32.0486 16.777C29.8224 16.777 28.0159 17.55 26.6291 19.0963C25.2788 20.6067 24.6037 22.4947 24.6037 24.7602C24.6037 27.0617 25.2971 28.9856 26.6839 30.5319C28.0707 32.0782 29.8589 32.8512 32.0486 32.8512Z"
								fill="currentColor"
							/>
						</g>
						<path
							d="M27.59 29.0385C27.59 32.5626 26.3127 35.2597 23.758 37.1296C21.2399 38.9996 18.0831 39.9345 14.2877 39.9345C9.83532 39.9345 6.36832 38.8557 3.88668 36.6981C1.44154 34.5045 0.145979 31.5557 0 27.8518H7.11647C7.29894 29.7577 7.99234 31.2501 9.19666 32.3289C10.4375 33.3717 12.1345 33.8932 14.2877 33.8932C16.0394 33.8932 17.481 33.5156 18.6123 32.7604C19.7436 31.9693 20.3093 30.8006 20.3093 29.2543C20.3093 28.3552 20.1086 27.5641 19.7071 26.8809C19.3422 26.1976 18.74 25.6222 17.9006 25.1548C17.0978 24.6513 16.3679 24.2737 15.711 24.022C15.0541 23.7703 14.1599 23.4826 13.0286 23.1589L10.8389 22.5117C4.41586 20.6057 1.20432 17.0996 1.20432 11.9932C1.20432 8.68484 2.4269 6.04174 4.87204 4.06391C7.31719 2.08608 10.4192 1.09717 14.1782 1.09717C18.0466 1.09717 21.1669 2.14002 23.5391 4.22573C25.9112 6.27548 27.1521 8.93656 27.2615 12.209H20.1451C19.9626 10.6627 19.3787 9.44001 18.3933 8.541C17.408 7.60602 16.0212 7.13853 14.2329 7.13853C12.5177 7.13853 11.1309 7.55208 10.0725 8.37917C9.01419 9.20626 8.48502 10.3031 8.48502 11.6696C8.48502 12.4247 8.64924 13.108 8.97769 13.7193C9.34264 14.2947 9.89006 14.7981 10.62 15.2297C11.3498 15.6252 12.0068 15.9309 12.5907 16.1466C13.2111 16.3624 14.0322 16.6321 15.0541 16.9558L16.97 17.5491C17.9189 17.8368 18.7583 18.1245 19.4882 18.4122C20.2546 18.6998 21.1669 19.1673 22.2253 19.8146C23.3201 20.4259 24.2142 21.1272 24.9076 21.9183C25.6375 22.6735 26.2579 23.6804 26.7689 24.939C27.3163 26.1616 27.59 27.5281 27.59 29.0385Z"
							fill="currentColor"
						/>
					</svg>
				`
			)}
		</span>`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		"tombstone-logo": TombstoneLogo;
	}
}
