import "./sign-out.js";
import { LitElement, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import styles from "./download-button.scss";
import { getAuth, signOut } from "firebase/auth";
import { download } from "./svgs.js";
import { classMap } from "lit/directives/class-map.js";

@customElement("download-button")
class DownloadButton extends LitElement {
	@property({ type: Boolean }) disabled = false;
	@property({ type: String }) token = "";

	static readonly styles = styles;

	render() {
		return html`
			<div id="container" class=${classMap({ disabled: this.disabled })}>
				<a
					href=${this.token ? `/download-archive/${this.token}` : "#"}
					download="archive.zip"
				>
					<button ?disabled=${this.disabled}>
						<span class="spacer"></span>
						${download}
						<span>Download</span>
						<span class="spacer"></span>
					</button>
				</a>
			</div>
		`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		"download-button": DownloadButton;
	}
}
