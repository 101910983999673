import {css} from "lit-element/lit-element.js";
export default css`#container {
  flex-direction: column;
  align-items: center;
  height: 100%;
  display: flex;
}
#container #button-container,
#container #loading {
  text-align: center;
}
#container a {
  color: inherit;
}
#container .signIn {
  font-size: 1.4em;
}
#container #footer {
  margin-top: 16px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9jbGllbnQvc3JjL3NpZ24taW4iLCJzb3VyY2VzIjpbImF1dGguc2NzcyIsIi4uL2NvbW1vbi9jb21tb24uc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNDO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0FBQUE7RUFFQzs7QUFHRDtFQUNDOztBQUdEO0VBQ0M7O0FBR0Q7RUFDQyxZQ2RhIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCBcIi4uL2NvbW1vbi9jb21tb24uc2Nzc1wiO1xuXG4jY29udGFpbmVyIHtcblx0ZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcblx0YWxpZ24taXRlbXM6IGNlbnRlcjtcblx0aGVpZ2h0OiAxMDAlO1xuXHRkaXNwbGF5OiBmbGV4O1xuXG5cdCNidXR0b24tY29udGFpbmVyLFxuXHQjbG9hZGluZyB7XG5cdFx0dGV4dC1hbGlnbjogY2VudGVyO1xuXHR9XG5cblx0YSB7XG5cdFx0Y29sb3I6IGluaGVyaXQ7XG5cdH1cblxuXHQuc2lnbkluIHtcblx0XHRmb250LXNpemU6IDEuNGVtO1xuXHR9XG5cblx0I2Zvb3RlciB7XG5cdFx0bWFyZ2luLXRvcDogJHNtYWxsLW1hcmdpbjtcblx0fVxufVxuIiwiJGZnLWNvbG9yOiAjMmEyYTJhO1xuJGJnLWNvbG9yOiAjZjVmNGYzO1xuJGRhcmstZ3JheTogIzJhMmEyYTtcbiRsaWdodC1ncmF5OiAjYmZiZWJmO1xuJGdyYXk6ICM5ZTljOTg7XG5cbiRsYXJnZS1tYXJnaW46IDQ4cHg7XG4kbWVkaXVtLW1hcmdpbjogMzJweDtcbiRzbWFsbC1tYXJnaW46IDE2cHg7XG4keC1zbWFsbC1tYXJnaW46IDhweDtcbiJdfQ== */`;
