import {css} from "lit-element/lit-element.js";
export default css`:host {
  display: flex;
}

#container {
  display: flex;
  white-space: nowrap;
  line-height: 1em;
  position: relative;
}
#container.lineBreak {
  display: block;
  word-spacing: -0.1em;
}
#container.lineBreak svg {
  height: 2em;
}
#container svg {
  height: 1em;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9jbGllbnQvc3JjL2NvbW1vbiIsInNvdXJjZXMiOlsibG9nby5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0M7OztBQUdEO0VBQ0M7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDQztFQUNBOztBQUVBO0VBQ0M7O0FBSUY7RUFDQyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcblx0ZGlzcGxheTogZmxleDtcbn1cblxuI2NvbnRhaW5lciB7XG5cdGRpc3BsYXk6IGZsZXg7XG5cdHdoaXRlLXNwYWNlOiBub3dyYXA7XG5cdGxpbmUtaGVpZ2h0OiAxZW07XG5cdHBvc2l0aW9uOiByZWxhdGl2ZTtcblxuXHQmLmxpbmVCcmVhayB7XG5cdFx0ZGlzcGxheTogYmxvY2s7XG5cdFx0d29yZC1zcGFjaW5nOiAtMC4xZW07XG5cblx0XHRzdmcge1xuXHRcdFx0aGVpZ2h0OiAyZW07XG5cdFx0fVxuXHR9XG5cblx0c3ZnIHtcblx0XHRoZWlnaHQ6IDFlbTtcblx0fVxufVxuIl19 */`;
