import { LitElement, html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { daysLeft } from "../common/date.js";
import styles from "./banner.scss";

@customElement("landing-banner")
class LandingBanner extends LitElement {
	static readonly styles = styles;

	render() {
		if (daysLeft() < 0) {
			return;
		}
		return html`
			<a href="/sign-in">
				<div id="container" class="notify">
					<div id="text">
						Sounds.Studio users have ${daysLeft()} days left to
						download their files <span id="arrow">→</span>
					</div>
				</div>
			</a>
		`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		"landing-banner": LandingBanner;
	}
}
