import { User } from "firebase/auth";
import { LitElement, html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { getShutdownDate } from "../common/date.js";
import { FRESH_DESK_SUPPORT } from "../common/links.js";
import { getAuthenticatedUser } from "../firebase/auth.js";
import "./download-button.js";
import styles from "./main.scss";
import "./sign-out.js";

@customElement("download-main")
class DownloadMain extends LitElement {
	@state() private downloading = false;
	@state() private user: User | null = null;
	@state() private token?: string;
	@state() private archiveExists: "checking" | "exists" | "not-exist" =
		"checking";

	protected async firstUpdated() {
		this.user = await getAuthenticatedUser();
		if (!this.user) {
			window.location.pathname = "/sign-in";
		}
		this.token = await this.user?.getIdToken();
		await this.doesDownloadExist();
	}

	private async makeRequest(url: string): Promise<Response> {
		if (!this.token) {
			throw new Error("requires authenticated user");
		}
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				token: this.token,
			}),
		});

		return response;
	}

	private async doesDownloadExist() {
		const response = await this.makeRequest("/download-exists");
		if (!response.ok) {
			return;
		}
		const { exists } = await response.json();
		this.archiveExists = exists ? "exists" : "not-exist";
	}

	static readonly styles = styles;

	render() {
		if (!this.user) {
			return html` <div id="container">
				<div class="spacer"></div>
				<div id="center" class="loading">Loading...</div>
				<div class="spacer"></div>
			</div>`;
		}
		return html`
			<div id="container">
				<div class="spacer"></div>
				<div id="center">
					<h1>Download your data</h1>
					<h5 id="subheading">
						${this.archiveExists === "checking"
							? "Checking for files..."
							: this.archiveExists === "exists"
								? `Archive ready for download.`
								: "No files to download."}
					</h5>
					<div id="download">
						<download-button
							token=${this.token}
							?disabled=${this.downloading ||
							!this.user ||
							this.archiveExists !== "exists"}
						></download-button>
					</div>
					<div id="info">
						<div id="support">
							<a href=${FRESH_DESK_SUPPORT} target="_blank"
								>Having trouble? Contact support</a
							>
						</div>
						<div id="info-header">
							The Sounds.Studio archive utility ran between June
							25th and July 18th, 2024. Your session was fully or
							partially archived at that time into a zip file
							which contains:
						</div>
						<div id="info-content">
							<ul>
								<li>
									Audio files from your library in a folder
									called
									<pre>library/</pre>
								</li>
								<li>
									A folder for each of your sessions labeled
									with its unique ID
								</li>
								<ul>
									<li>
										Individual audio clips from your
										sessions each with a unique ID.
									</li>
									<li>
										A
										<pre>data.json</pre>
										file inside of your session folder that
										describes the start time of the clips in
										the session
									</li>
								</ul>
							</ul>
						</div>
						<div id="expires">
							You have until
							<i>${getShutdownDate()}</i>
							to download your data. After that, this page will be
							unavailable and your data will be deleted.
						</div>
					</div>
				</div>
				<div id="sign-out">
					<download-sign-out></download-sign-out>
				</div>
				<div class="spacer"></div>
			</div>
		`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		"download-main": DownloadMain;
	}
}
