import { getAuth } from "firebase/auth";
import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";
import styles from "./sign-out.scss";

@customElement("download-sign-out")
class DownloadSignOut extends LitElement {
	private async signOut() {
		await getAuth().signOut();
		window.location.pathname = "/";
	}

	static readonly styles = styles;

	render() {
		return html`
			<div id="container">
				<button @click=${this.signOut}>Sign out</button>
			</div>
		`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		"download-sign-out": DownloadSignOut;
	}
}
